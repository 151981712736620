import React from "react";
import Image from "../common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import "@styles/Collaborations.css";

const Collaborations = props => {
    return (
        <section className={`collaborations ${props.className}`}>
            <div className="container lg:px-0 py-20">
                <Title className="">Colaboraciones</Title>
                <Text className="text-justify mb-10">
                    Nos gusta sentirnos útiles y necesarios. Con nuestro trabajo nos comprometemos
                    en ayudar a otras personas, en especial a los niños que lo necesitan. Proyectos
                    muy costosos que necesitan la unión y colaboración de muchas personas. Cuando
                    compráis uno de nuestros libros 1€ va destinado a una causa.
                    <br />
                    <br />
                    Con vuestra generosidad y solidaridad ponemos entre todos un granito de arena en
                    la lucha y la esperanza de esos niños.
                </Text>

                {/** Enfermedades Raras: Abril */}
                <div className="">
                    <h3 className="text-cpurple uppercase inline-block border-b border-gray-300 pb-2 font-montserrat font-semibold">
                        Lucha contra las enfermedades raras @laluchadeabril
                    </h3>
                    <Text className="text-justify text-sm mt-5">
                        Abril tiene cuatro años y hace dos fue diagnosticada con Paraparesia
                        Espástica del Tipo 52, también conocida como SPG52, una enfermedad de las
                        catalogadas como ultra raras, que solo le ha sido diagnosticada a 8 niños en
                        el mundo, siendo Abril la única en España.
                        <br />
                        Se trata de una enfermedad neuromuscular degenerativa, cuyos síntomas
                        principales son un déficit cognitivo entre moderado y severo, falta del
                        habla, posibles ataques epilépticos y espasticidad progresiva en las
                        piernas. En el día a día de Abril son muy importantes las terapias a las que
                        asiste para intentar ralentizar la evolución de los síntomas. Actualmente
                        asiste a fisioterapia, logopeda, terapia ocupacional y equinoterapia.
                        <br />
                        Para ello es necesaria mucha investigación; pero, por desgracia, las
                        enfermedades raras, y más aún las ultra raras, a veces no disponen de la
                        suficiente financiación. Por eso, han sido ellos mismos los que se han
                        puesto en contacto con investigadores de la Universidad Autónoma de
                        Barcelona. En total el procedimiento completo requeriría alrededor de un
                        millón de euros, por lo que todavía queda mucho por andar.
                        <br />
                        <br />
                        Más información en la web{" "}
                        <a
                            href="https://laluchadeabril.org/"
                            target="_blank"
                            className="default-link"
                            rel="noopener noreferrer"
                        >
                            laluchadeabril.org
                        </a>
                        .
                    </Text>

                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-10">
                        <Image
                            className="image"
                            alt={"@laluchadeabril moybu iopos método a"}
                            filename="collaborations/abril5"
                        />

                        <Image
                            className="image"
                            alt={"@laluchadeabril moybu iopos método a"}
                            filename="collaborations/abril1"
                        />

                        <Image
                            className="image"
                            alt={"@laluchadeabril moybu iopos método a"}
                            filename="collaborations/abril3"
                        />

                        <Image
                            className="image"
                            alt={"@laluchadeabril moybu iopos método a"}
                            filename="collaborations/abril2"
                        />

                        <Image
                            className="image"
                            alt={"@laluchadeabril moybu iopos método a"}
                            filename="collaborations/abril4"
                        />
                    </div>
                </div>

                {/** Escudos Solidarios */}
                <div className="mt-20">
                    <h3 className="text-cpurple uppercase inline-block border-b border-gray-300 pb-2 font-montserrat font-semibold">
                        Escudos Solidarios #pelsvalents
                    </h3>
                    <Text className="text-justify text-sm mt-5">
                        <strong>Escudos solidarios contra el cáncer infantil</strong>, es una
                        iniciativa para ayudar en la recaudación de fondos de la campaña{" "}
                        <i>#pelsvalents</i> <i>#paralosvalientes</i> del{" "}
                        <strong>Hospital Sant Joan de Déu de Barcelona</strong>. La colaboración se
                        hace a través de la venta de escudos bordados con la placa-emblema de cada
                        Policía Local y que incorpora, además, el contorno amarillo propio de la
                        lucha contra el cáncer infantil y el hashtag de la campaña{" "}
                        <i>#pelsvalents</i>.
                        <br />
                        <br />
                        Infinidad de municipios de toda España se han sumado, y desde iGuB hemos
                        querido unirnos a esta iniciativa solidaria y aportar nuestro granito de
                        arena confeccionando nuestros propios escudos, y ofreciéndolo a todos los
                        que queráis colaborar en esta causa.
                        <br />
                        <br />
                        <strong>Todo el dinero recaudado</strong> se destina a la investigación del
                        cáncer infantil y a la construcción del{" "}
                        <strong>Hospital Pediàtric Cancer Center de Barcelona</strong>, que se
                        convertirá en el mayor centro oncológico infantil de Europa y dará
                        asistencia médica a niños y niñas de cualquier comunidad autónoma española.
                    </Text>

                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-10">
                        <Image
                            className="image"
                            alt={"#pelsvalents gub"}
                            filename="collaborations/pelsvalents1"
                        />

                        <Image
                            className="image"
                            alt={"#pelsvalents moybu"}
                            filename="collaborations/pelsvalents2"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Collaborations;
