import React from "react";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import Image from "../common/CloudinaryImage";
import { FaTwitter, FaGithub, FaFacebook, FaInstagram, FaLinkedin, FaLink } from "react-icons/fa";
import teamMembers from "../../data/Team";
import "../../styles/Team.css";

const Team = props => {
    // Reordenamos los members de forma aleatoria
    teamMembers.sort(function (a, b) {
        return 0.5 - Math.random();
    });

    return (
        <>
            <section className={`${props.className}`}>
                <div className="container lg:px-0 py-20">
                    <Title className="text-center">Nuestro equipo</Title>
                    <Text className="text-center">
                        Somos un equipo de profesionales, con{" "}
                        <strong>más de 20 años de experiencia</strong> en nuestras áreas. Desde 2014
                        damos apoyo integral a las personas que quieren{" "}
                        <strong>preparar oposiciones</strong>. Somos desarrolladores de aplicaciones
                        y expertos en oposiciones para acceder a los{" "}
                        <i>Cuerpos de Seguridad del Estado</i> y <i>la Administración Pública</i>.
                        <br />
                        <br />
                        Nuestros clientes nos eligen porque desarrollamos aplicaciones
                        verdaderamente útiles para el usuario, desde el conocimiento y background de
                        las oposiciones. Además, mantenemos una atención al cliente excelente y
                        generamos comunidad alrededor de las aplicaciones, compartiendo contenido
                        útil.
                    </Text>

                    {teamMembers && (
                        <div className="mt-10 gap-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                            {teamMembers.map(t => {
                                return <TeamMember key={t.id} {...t} />;
                            })}
                        </div>
                    )}

                    {/*<div className="mt-10 rounded overflow-hidden">
                        <Image
                            className="image"
                            alt={"iOpos Team"}
                            filename={`team/team`}
                        />
                    </div>*/}
                </div>
            </section>
        </>
    );
};
export default Team;

const TeamMember = props => {
    const renderSocial = data => {
        if (data) {
            const social = data.map((item, index) => {
                switch (item.type) {
                    case "linkedin":
                        return (
                            <a
                                key={index}
                                target="_blank"
                                aria-label="LinkedIn"
                                rel="noopener noreferrer"
                                href={item.url}
                                className={`icon-link ${
                                    data.length - 1 === index ? "mr-0" : "mr-5"
                                }`}
                            >
                                <FaLinkedin
                                    className="inline-block h-7 w-7"
                                    style={{ verticalAlign: "middle" }}
                                />
                            </a>
                        );

                    case "github":
                        return (
                            <a
                                key={index}
                                target="_blank"
                                aria-label="Github"
                                rel="noopener noreferrer"
                                href={item.url}
                                className={`icon-link mr-5 ${
                                    data.length - 1 === index ? "mr-0" : "mr-5"
                                }`}
                            >
                                <FaGithub
                                    className="inline-block h-7 w-7"
                                    style={{ verticalAlign: "middle" }}
                                />
                            </a>
                        );

                    case "facebook":
                        return (
                            <a
                                key={index}
                                target="_blank"
                                aria-label="Facebook"
                                rel="noopener noreferrer"
                                href={item.url}
                                className={`icon-link mr-5 ${
                                    data.length - 1 === index ? "mr-0" : "mr-5"
                                }`}
                            >
                                <FaFacebook
                                    className="inline-block h-7 w-7"
                                    style={{ verticalAlign: "middle" }}
                                />
                            </a>
                        );

                    case "twitter":
                        return (
                            <a
                                key={index}
                                target="_blank"
                                aria-label="Twitter"
                                rel="noopener noreferrer"
                                href={item.url}
                                className={`icon-link mr-5 ${
                                    data.length - 1 === index ? "mr-0" : "mr-5"
                                }`}
                            >
                                <FaTwitter
                                    className="inline-block h-7 w-7"
                                    style={{ verticalAlign: "middle" }}
                                />
                            </a>
                        );

                    case "instagram":
                        return (
                            <a
                                key={index}
                                target="_blank"
                                aria-label="Instagram"
                                rel="noopener noreferrer"
                                href={item.url}
                                className={`icon-link mr-5 ${
                                    data.length - 1 === index ? "mr-0" : "mr-5"
                                }`}
                            >
                                <FaInstagram
                                    className="inline-block h-7 w-7"
                                    style={{ verticalAlign: "middle" }}
                                />
                            </a>
                        );

                    case "web":
                        return (
                            <a
                                key={index}
                                target="_blank"
                                aria-label="Página Web"
                                rel="noopener noreferrer"
                                href={item.url}
                                className={`icon-link mr-5 ${
                                    data.length - 1 === index ? "mr-0" : "mr-5"
                                }`}
                            >
                                <FaLink
                                    className="inline-block h-7 w-7"
                                    style={{ verticalAlign: "middle" }}
                                />
                            </a>
                        );

                    default:
                        return null;
                }
            });
            return social;
        } else {
            return null;
        }
    };

    const renderedSocial = renderSocial(props.social);

    return (
        <article className="team">
            <div className="text-center">
                <Image className="image" alt={props.name} filename={`team/${props.image}`} />

                <div className="px-5">
                    <h3 className="h3">{props.name}</h3>
                    <h4 className="h4">{props.text}</h4>
                    <hr className="hr " />
                    {props.social && <div className="icons">{renderedSocial}</div>}
                </div>
            </div>
        </article>
    );
};
