import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import Team from "@components/sections/Team";
import Collaborations from "@components/sections/Collaborations";
import SmallBanner from "@components/common/SmallBanner";
import OurMission from "@components/sections/OurMission";

export default function Nosotros() {
    return (
        <>
            <Layout>
                <Seo
                    title="Nosotros"
                    description="¿Quién somos? Te mostramos nuestro equipo principal, nuestros valores y nuestras colaboraciones"
                />
                <SmallBanner
                    title="¿Quiénes somos?"
                    subtitle="Te mostramos quien somos, nuestros valores y nuestras colaboraciones"
                    filename={"bgs/bgTeam"}
                />

                <Team />

                <OurMission className="bg-gray-100 bg-opacity-50" />

                <Collaborations />
            </Layout>
        </>
    );
}
