"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var team = [
    {
        id: 1,
        name: "Francesc Llaó",
        text: "Desarrollador FrontEnd y Mobile",
        image: "francesc",
        social: [
            {
                type: "linkedin",
                url: "https://www.linkedin.com/in/francesc-lla%C3%B3-garcia-a8329750/",
            },
        ],
    },
    {
        id: 3,
        name: "Dario Quiroga",
        text: "Contenidos y Márqueting",
        image: "dario",
        social: [
            {
                type: "linkedin",
                url: "https://www.linkedin.com/in/dario-quiroga-35257721/",
            },
        ],
    },
    {
        id: 4,
        name: "Àlex Llaó",
        text: "Desarrollador FrontEnd, BackEnd y Mobile",
        image: "alex",
        social: [
            {
                type: "linkedin",
                url: "https://www.linkedin.com/in/alexllao",
            },
            {
                type: "github",
                url: "https://www.github.com/alexllao",
            },
        ],
    },
    {
        id: 5,
        name: "Alberto Guisado",
        text: "Formación y Contenidos",
        image: "alberto",
        social: null,
    },
];
exports.default = team;
