import React from "react";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import Image from "../common/CloudinaryImage";

const OurMission = props => {
    return (
        <>
            <section className={`${props.className}`}>
                <div className="container lg:px-0 py-20">
                    <Title className="text-left">
                        Nuestra misión: Hacer realidad grandes ideas
                    </Title>
                    <Text className="text-justify">
                        Nuestro <strong>principal objetivo</strong> es aprovechar toda nuestra
                        experiencia desde 2013 y ponerla a <strong>vuestro servicio</strong>.
                        <br />
                        Nuestros proyectos están orientados a que <strong>aprendas</strong> lo más
                        rápido posible con las <strong>máximas garantías</strong> de éxito.
                        <br />
                        La experiencia de estos años nos ha demostrado que somos la{" "}
                        <strong>mejor herramienta</strong> para complementar tu estudio, ya sea con
                        nuestras app's, libros o cursos.
                    </Text>
                    <Image
                        filename="bgs/bgBigIdeas"
                        className="mt-10 rounded max-h-16 shadow"
                        alt="iopos nuestra misión"
                    />
                </div>
            </section>
        </>
    );
};
export default OurMission;
